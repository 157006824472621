import { useState } from "react";
import { Image } from "./image_gallery/Image";
import { srcVinilos } from "../data/image_db";
import { Modal } from "./image_gallery/Modal";

function Ecoamigables() {
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalHidden, setIsModalHidden] = useState(true);

  const currentIndex = srcVinilos.findIndex((img) => img === imageSrc);

  const openImg = (e) => {
    setImageSrc(e.target.src);
    setIsModalHidden(false);
  };

  const closeImg = (e) => {
    setIsModalHidden(true);
  };

  const prev = () => {
    const prevIndex = (currentIndex - 1 + srcVinilos.length) % srcVinilos.length;
    setImageSrc(srcVinilos[prevIndex]);
    console.log("this should pass the url for the prev imgSrc");
  };

  const next = () => {
    const nextIndex = (currentIndex + 1) % srcVinilos.length;
    setImageSrc(srcVinilos[nextIndex]);
    console.log("this should pass the url for the next imgSrc");
  };

  return (
    <div className="main-gy-container">
      <h1>Ecoamigables</h1>
      <p>Te ofrecemos productos utilitarios.</p>
      <p>
        Sencillos, cómodos, prácticos, económicos y amigables con el ecosistema.
      </p>
      <div className="gy-container">
        {srcVinilos.map((img, index) => {
          return <Image key={index} src={img} openImg={openImg}/>;
        })}
      </div>
      <Modal
        isHidden={isModalHidden}
        imageSrc={imageSrc}
        closeImg={closeImg}
        handlePrevious={prev}
        handleNext={next}
      />
    </div>
  );
}

export default Ecoamigables;
