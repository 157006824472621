import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../img/reinamomo.png";
import "../stylesheets/Navbar.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const openLinks = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <nav className={isOpen ? "topnav responsive" : "topnav"} id="nav-bar">
      <button className="navbar_toggle" onClick={openLinks}>
        <span className={!isOpen ? "arrow-down show-arrow" : "arrow-down"}>
          ♻
        </span>
      </button>
      <NavLink className="nav-link_1" to="/">
        <img
          className={
            !isOpen ? "header-logo nav-link show-arrow" : "header-logo nav-link"
          }
          src={Logo}
          alt="reinamomo logo"
        />
      </NavLink>
      <NavLink className="nav-link" to="/">
        Origenes
      </NavLink>
      <NavLink className="nav-link" to="/Ecoamigables">
        Ecoamigables
      </NavLink>
      <NavLink className="nav-link" to="/Personalizados">
        Personalizados
      </NavLink>
      <NavLink className="nav-link" to="/Materiales">
        Materiales
      </NavLink>
      <NavLink className="nav-link" to="/Sanarte">      
        Sanarte
      </NavLink>
    </nav>
  );
}

export default Navbar;
