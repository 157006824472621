import "../../stylesheets/Modal.css";

export function Modal({
  isHidden,
  imageSrc,
  closeImg,
  handlePrevious,
  handleNext,
}) {
  const handleInsideClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={isHidden ? "img-modal" : "img-modal-open"}
      onClick={closeImg}
    >
      <div className="modal-content">
        <button
          className="prev"
          onClick={(e) => {
            handleInsideClick(e);
            handlePrevious();
          }}
          aria-label="Previous image"
        >
          ❮
        </button>

        <img
          className="modal-img"
          src={imageSrc}
          alt="digital art by Charly BGood"
        />
        <button
          className="next"
          onClick={(e) => {
            handleInsideClick(e);
            handleNext();
          }}
          aria-label="Next image"
        >
          ❯
        </button>
      </div>
    </div>
  );
}
