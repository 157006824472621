import vinilo1 from "../img/vinilo1.jpg";
import vinilo2 from "../img/vinilo2.jpg";
import ecoamigables2 from "../img/ecoamigables2.png";
import vinilo4 from "../img/vinilo4.jpg";
import ecoamigables1 from "../img/ecoamigables1.png";
import vinilo6 from "../img/vinilo6.jpg";
import ecoamigables4 from "../img/ecoamigables4.png";
import ecoamigables3 from "../img/ecoamigables3.png";
import cassette1 from "../img/cassette1.jpg";
import cassette2 from "../img/cassette2.jpg";
import cassette3 from "../img/cassette3.jpg";
import collage1 from "../img/collage1.jpg";
import collage2 from "../img/collage2.jpg";
import collage3 from "../img/collage3.jpg";
import collage4 from "../img/collage4.jpg";

export const srcCassettes = [cassette1, cassette2, cassette3];

export const srcCollage = [collage1, collage2, collage3, collage4];

export const srcVinilos = [
  vinilo1,
  vinilo2,
  ecoamigables1,
  vinilo4,
  ecoamigables2,
  vinilo6,
  ecoamigables4,
  ecoamigables3,
];
