import "../stylesheets/Footer.css";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <footer>
      <div className="smedia">
        <Link
          to="https://www.instagram.com/reinamomoartesana/"
          target="_blank"> 
          <i>En redes sociales:</i>
        <FaInstagram />
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
