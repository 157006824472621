import "../stylesheets/Main.css";

function Main() {
  return (
    <div id="Inicio" className="container">
      <h1 className="title">ReinaMomoArteSana</h1>
      <div className="logo_button" />
      <p>Satisfacer necesidades de las generaciones presentes</p>
      <p>Sin comprometer las posibilidades de generaciones futuras.</p>
    </div>
  );
}

export default Main;
