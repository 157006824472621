import "../stylesheets/Sanarte.css";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

function Sanarte() {
  return (
    <section id="Sanarte" className="container">
      <h2>Sanarte</h2>
      <p>Cuando algo te genere conflicto asume que necesita amor y dáselo.</p>
      <p>Atención terapéutica floral complementaria. Sistema Bach.</p>
      <div className="logo_button_two" />
      <div className="action">
        <button id="call">
          <Link to="https://wa.me/5491126395112" target="_blank">
            <i>Contactanos</i>
            <FaWhatsapp />
          </Link>
        </button>
      </div>
    </section>
  );
}

export default Sanarte;
